








// CORE
import { Component, Mixins } from 'vue-property-decorator'

// MIXINS
import NotifyMixin from '@/mixins/NotifyMixin'

// COMPONENTS
import DrawerHeader from '@/components/drawers/DrawerHeader.vue'
import RegistrationForm from '@/components/forms/RegistrationForm.vue'

// STORE
import AuthModule from '@/store/modules/auth'
import { IRegisterForm } from '@/store/types'

@Component({
  components: {
    DrawerHeader,
    RegistrationForm,
  },
})
export default class Registration extends Mixins(NotifyMixin) {
  private get roleType () {
    return AuthModule.roleType
  }

  private handleRoleChange (value: any) {
    AuthModule.setRole(value.value)
  }

  private sendForm (form: IRegisterForm) {
    AuthModule.sendRegisterForm(form)
      .then(data => {
        let entity = ''
        if (this.roleType === 'driver') {
          entity = (data?.cars[0]?.id || '').toString()
        } else if (this.roleType === 'fleet') {
          entity = (data?.carParks[0]?.id || '').toString()
        } else {
          entity = (data?.carGarages[0]?.id || '').toString()
        }

        this.$router.push({
          name: 'profile',
          params: {
            role: this.roleType,
            entity: entity,
          },
        })

        this.notifySuccess('Регистрация прошла успешно')
      })
      .catch(error => {
        this.notifyError(error)
      })
  }
}
